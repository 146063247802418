$(window).resize(function(){
    // reset the seq for charts drawing animations
    seq = seq2 = 0;

    if($('.full-screen-map').length == 0 && $('.bd-docs').length == 0){
      $navbar = $('.navbar');
      isExpanded = $('.navbar').find('[data-toggle="collapse"]').attr("aria-expanded");
      if( $navbar.hasClass('bg-white') && $(window).width() > 991 ){
        $navbar.removeClass('bg-white').addClass('navbar-transparent');
      } else if( $navbar.hasClass('navbar-transparent') && $(window).width() < 991 && isExpanded != "false" ) {
        $navbar.addClass('bg-white').removeClass('navbar-transparent');
      }
    }
});
