 transparent = true;
 transparentDemo = true;
 fixedTop = false;

 navbar_initialized,
    backgroundOrange = false,
    sidebar_mini_active = false,
    toggle_initialized = false;

 seq = 0, delays = 80, durations = 500;
 seq2 = 0, delays2 = 80, durations2 = 500;
